import React, { useEffect, useState } from "react";
import Warning from '../assets/images/Sigmawarning.png';
import './Modal.css';
function Modal(props) {
  const [isOpen, setIsOpen] = useState({...props.open});

  useEffect(() => {
      setIsOpen(props.open);
  }, [props.open]);

  const close = () => {
    props.close();
  };

  return isOpen ? (
    <div className="modal-overlay" onClick={close}>
      <div className="modal-content" onClick={(e) => { e.stopPropagation(); }}>
        <button type="button" className="close" onClick={close}>&times;</button>
        <img src={Warning} alt="" className="warning" />
        <p>{props.message}</p>
      </div>
    </div>
  ) : (<></>);
}

export default Modal;
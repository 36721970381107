import React, { useEffect, useState } from "react";
import { useDispatch, useSelector  } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData} from "./redux/data/dataActions";
import { useParams } from 'react-router-dom'
import { ethers } from "ethers";
import store from "./redux/store";

import './App.scss';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Modal from './components/Modal';
import ClaimBox from './components/ClaimBox';
import HistoryBox from './components/HistoryBox';

function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  console.log("data.total ", data.total);
  const [txData, setTxData] = useState([]);
  const [withdraw, setWithdraw] = useState(false);
  const [firstModal, setFirstModal] = useState(true);
  const [errorModal, setErrorModal] = useState("");
  const { address } = useParams()
  const getData = () => {
    if ((blockchain.account !== undefined && blockchain.account !== null) && blockchain.vaultcontract !== null) {
      console.log("here");
      dispatch(fetchData(blockchain.account));
    }
  };

  store.subscribe(() => {
    setErrorModal(store.getState().blockchain.errorMsg);
  });

  useEffect(() => {
   // console.log('empty call')
   // dispatch(connect(address));
  }, []);

  useEffect(() => {
    console.log('address call')
    dispatch(connect(address));
  }, [address]);

  useEffect(() => {
    console.log("blockchain.account", blockchain.account);
    if (blockchain.account !== undefined && blockchain.account !== null)
      getData();
  }, [blockchain.account]);

  const connectWallet = (e) =>{
    e.preventDefault();
    console.log("connectWallet", address);
    dispatch(connect(address));
  }

  const connectWallet_para = () =>{
    console.log("connectWallet", address);
    dispatch(connect(address));
  }

  const closeErrorModal = () => {
    setErrorModal("");
  }

  const ev_withdraw = async (e) => {
    e.preventDefault();
    if (data.unlocked > 0)
    {
      setWithdraw(true);
      await blockchain.vaultcontract.methods.withdraw(blockchain.account, data.unlocked).send({
        from:blockchain.account,
      }).once("error", (err) => {
        console.log(err);
        setWithdraw(false);
      })
      .then((receipt) => {
        setWithdraw(false);
      });
      getData();
    }
    else 
      setErrorModal("Not available.");
  }

  const string_makeShort = (str) => {
    return str.substring(0,6)+"..."+str.substring(str.length - 3, str.length);
  }
  const see_transaction = async (e) => {
    setFirstModal(false);
    let txList = [];
    const apiUrl = blockchain.apiUrl+'api?module=logs&action=getLogs&fromBlock=0&toBlock=latest&address='+address+'&apikey='+blockchain.apiKey;
    fetch(apiUrl)
      .then((response) => response.json())
      .then( async function(data) {
          await Promise.all(data.result.map(async (tx) => {
            console.log("tx", tx);
            let timestamp = await blockchain.web3.eth.getBlock(parseInt(tx.blockNumber));
            let detail = await blockchain.web3.eth.getTransactionReceipt(tx.transactionHash);
            let value = "0x"+tx.data.substring(tx.data.length-64, tx.data.length);
            console.log("value", value);
            txList.push({
              id: string_makeShort(tx.transactionHash),
              vaultaddress: string_makeShort(tx.address),
              destination: string_makeShort(detail.from),
              timestamp: timestamp.timestamp,
              claimamount: ethers.BigNumber.from(value).div("1000000000000000000").toString(),
            });
          }));
          setTxData(txList);
      });
  }

  const see_firstModal = (e) => {
    setFirstModal(true);
  }
  const d_withdraw = () => {
    if (withdraw === false)
      return (
        <div className = "flex items-center ">
          <button onClick={(e) => { ev_withdraw(e) }} type="button" className="m-3 w-[138px] rounded-[10px] h-9 border text-[#404C55] h-[46px] mt-6 border-gray-700 focus:border-gray-100 hover:border-lime-800 font-medium text-sm px-5 py-2.5 text-center ">
            Withdraw
          </button>
          <button onClick={(e) => { see_transaction(e) }} type="button" className="m-3 w-[138px] rounded-[10px] h-9 border text-[#404C55] h-[46px] mt-6 border-gray-700 focus:border-gray-100 hover:border-lime-800 font-medium text-sm px-5 py-2.5 text-center ">
            History
          </button>
        </div>
      )
    else 
    return (
      <button type="button" disabled = "disabled" className="w-[138px] rounded-[10px] h-9 border text-[#404C55] h-[46px] mt-6 border-gray-700 focus:border-gray-100 hover:border-lime-800 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center ">
        Processing...
      </button>
    )
  }

  return (
    <div className="font-sans wrapper">
      <div className = "container" >
        <Header add = {address} wallet = {blockchain.account} blockchain = {blockchain} connectWallet_para = {(e)=>connectWallet(e)}/>
        <main className='mt-36 min-h-screen py-8 text-white flex flex-row justify-center'>
          {
          firstModal ? 
            <ClaimBox data = {data} blockchain = {blockchain} connectWallet = {(e)=> connectWallet(e)} d_withdraw = {() => d_withdraw()}></ClaimBox> : 
            <HistoryBox txData = {txData} see_firstModal = {(e)=>see_firstModal(e)}></HistoryBox>
          }
          <Modal open={errorModal != ""} message={errorModal} close={closeErrorModal} />
        </main>
        <Footer/>
      </div>
    </div>
  );
}

export default Home;
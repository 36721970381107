import React from "react";
import { ethers } from "ethers";
import './ClaimBox.css';
//import './Header.css';
export default function Header({data, blockchain, connectWallet, d_withdraw}) {
    return (
        <div className="p-4 md:w-[604px] w-[402px] h-max rounded-[20px] bg-white border shadow-md sm:p-6 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-col items-center pb-2 pt-2">
          <p className="mb-5 text-[#404C55] text-[38px] font-bold">
              Claim SDEX
          </p>
          <p className="mb-7 text-[#404C55] text-[14px]">
            Your vault balances are displayed below:
          </p>
          <img src = "/images/sigmalock.png" alt = "lock" className = "mb-5" style = {{width:"31px", height:"34px"}}/>
            <ul className="my-4 space-y-3 bg-white border shadow-md rounded-[20px] md:w-[500px] w-[300px]">
                <li className="flex items-center justify-between pl-3 pr-3 pt-3 text-base font-bold text-gray-900  rounded-lg">
                    <span className="flex flex-row items-center ">
                        <span className = "mr-4 ml-3" style = {{position:"relative"}}>
                        <img src = "/images/sdexlock.png" alt = "sdexlock" className = ""/>
                        <img src = "/images/sigmaGroup.png" alt = "timer" className = "" style = {{position:"absolute", left:"26px", top:"13px", width: "13px", height:"21px"}}/>
                        </span>
                        <span className = "text-[#404C55] text-[14px]"> Locked:
                        </span>
                    </span>
                    <span className="text-[#404C55] text-[14px] mr-5">
                    {!data.total ? "N/A" : ethers.BigNumber.from(data.total).sub(data.unlocked).div("1000000000000000000").toString()}
                    </span>
                </li>
                <li className="flex items-center justify-between p-3 text-base font-bold text-gray-900  rounded-lg">
                    <span className="flex flex-row items-center ">
                        <span className = "mr-4 ml-3">
                        <img src = "/images/sdex.png" alt = "sdexlock" className = ""/>
                        </span>
                        <span className = "text-[#404C55] text-[14px]"> Available:
                        </span>
                    </span>
                    <span className="text-[#404C55] text-[14px] mr-5">
                    {!data.unlocked ? "N/A" : ethers.BigNumber.from(data.unlocked).div("1000000000000000000").toString()}
                    </span>
                </li>
            </ul>
          {!blockchain.account ? (
            <button onClick={(e) => { connectWallet(e) }} type="button" className="w-[138px] rounded-[10px] h-9 border text-[#404C55] h-[46px] mt-6 border-gray-700 focus:border-gray-100 hover:border-lime-800 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center ">
                Connect Wallet
            </button>
          ) : d_withdraw()}
        </div>
      </div>
        
  )
}

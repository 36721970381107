import React from "react";
import { ethers } from "ethers";
import './HistoryBox.css';

export default function HistoryBox({txData, see_firstModal}) {
    return (
        <div className="p-4 md:w-[804px] w-[602px] h-max rounded-[20px] bg-white border shadow-md sm:p-6 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-col items-center pb-2 pt-2">
          <p className="mb-5 text-[#404C55] text-[38px] font-bold">
              Claim SDEX History
          </p>
          <p className="mb-7 text-[#404C55] text-[14px]">
            The following SDEX claim transactions have occurred:
          </p>
          <div className="my-4 space-y-3 bg-white relative overflow-x-auto shadow-md rounded-[20px] md:w-[700px] w-[500px]">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
              <thead className="text-xs text-[#404C55] ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                      TX ID
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Timestamp
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Vault Address
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Destination
                  </th>
                  <th scope="col" className="px-6 py-3">
                      Claim Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                   
                   txData.map(tx => (
                    <tr className="bg-white dark:bg-gray-800 dark:border-gray-700" key={tx.id}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {tx.id}
                      </th>
                      <td className="px-6 py-4">
                        {tx.timestamp}
                      </td>
                      <td className="px-6 py-4">
                        {tx.vaultaddress}
                      </td>
                      <td className="px-6 py-4">
                        {tx.destination}
                      </td>
                      <td className="px-6 py-4">
                        {tx.claimamount}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          
          <div className = "flex flex-row justify-end items-center md:w-[700px] w-[500px]">
            <button onClick={(e) => { see_firstModal(e) }} type="button" className="m-3 w-138 rounded-[10px] h-9 border text-[#404C55] h-[46px] mt-6 border-gray-700 focus:border-gray-100 hover:border-lime-800 font-medium text-sm px-5 py-2.5 text-center inline-flex">
              Next
            </button>
          </div>
        </div>
      </div>
    )
}
